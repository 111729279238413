input:focus,
input:focus-within,
input:focus-visible,
input:active,
input:hover{
 outline:0;
}
input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], textarea {
  padding: 1rem 2rem;
  color: var(--nav_color);
  border: 1px solid var(--border);
  font-family: 'Libre Franklin', sans-serif;
  border-radius: 100px;
}
button, a.button {
  cursor: pointer;
  box-shadow:var(--box-shadow);
  font-weight: 700;
  background-color: var(--main_accent);
  color: white;
  padding-top:8px;
  padding-bottom:8px;
  display: flex;
  align-items:center;
  justify-content:center;
  border-radius: 6px;
  text-decoration: none;
  width:100%;
  text-align:center;
  transition: all 0.5s ease;
  border: 2px solid var(--main_accent);
  margin-top:1rem;
}
button:hover, a.button:hover  {
  background: white;
  color: var(--main_accent);
  transition: all 0.5s ease;
  box-shadow:none;
}

button.secondary, a.button.secondary { 
  background: var(--dark_accent);
  border: 2px solid var(--dark_accent);
}
button.tertiary, a.button.tertiary {
  background: white;
  color: 2px solid var(--dark_accent);
  border: 2px solid var(--dark_accent);
}
button.secondary:hover, a.button.secondary:hover  {
  color:white;
}

button.loud {
  background: #3ab54b;
  color: #fff;
  border: 2px solid #3ab54b;
}
button.loud:hover{
  color: #3ab54b;
  background: #fff;
}