/* LAYOUT */
body{display:flex;}
#root{width:100%;}
img {
  height: auto;
  width: auto;
  max-width: 100%;
}

.center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}
.hide{display:none !important;}
section {
  padding: 2rem;
  margin: 0 auto;
}
/* .callout-wrap{
  padding: 2rem;
} */

.container{
  width:100%;
  max-width: 1000px;
  margin: 0 auto;
}
footer .container {
  display: flex;
  justify-content: space-between;
}
.section-text {
  padding:2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-direction: column;
}
.full-bleed {
  padding: 0;
}
.full-bleed .section-image img{
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.callout{
  border-top: 3px solid var(--dark_accent);
}
.flanked .container{
  display: flex;
  align-items: center;
}
.flanked img{
  width: 25%;
  max-height:350px;
}
 .flanked .section-text{    
  max-width: 44rem;
  margin: 0 auto;
  padding: 0 2rem;
}
section h2 {
  align-items: center;
  text-align: center;
}
.divider{
  align-items: center;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}
section h2:after,
.divider:before{
  content:"";
  border-bottom:1px solid var(--dark_accent);
  width:5rem;
  margin: 2rem 0 1.5rem 0;
}

.dark-bg {
  background: var(--dark_accent);
}
.highlighted {
  background: var(--highlighted);
}
.dark-bg p,
.dark-bg a,
.dark-bg h1,
.dark-bg h2,
.dark-bg h3,
.dark-bg h4,
.dark-bg h5,
.dark-bg h6,
.dark-bg p,
.dark-bg ul {
  color: white;
}
.dark-bg h2:after{border-bottom: 1px solid white;}

.dark-bg a,
.dark-bg a:hover,
.dark-bg a:focus,
.dark-bg a:active,
.dark-bg a:-webkit-any-link {
  color: white;
  text-decoration: underline;
}
.dark-bg .icon-card p,
.dark-bg .icon-card a,
.dark-bg .icon-card h3 {color: initial;}
.dark-bg .icon-card a.button.secondary{color:white;text-decoration:none;}
.dark-bg .icon-card a.button.tertiary{color:var(--dark_accent);text-decoration:none;}

@media screen and (min-width: 576px) {
  section {
    padding: 3rem;
  }
  .section-text{
    padding: 3rem;
  }
  /* 
  section.full-bleed {
    padding: 0;
  }
  section.full-bleed .section-text {
    padding: 2rem;
  } */
}
@media screen and (min-width: 768px) {
  section {
    padding: 4rem;
  }
  .section-text{
    padding: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  /* section{padding-left:0;padding-right:0;} */
  section .container {
    margin:0 auto;
  }
  section {
    padding: 6rem;
  }
  .section-text{
    padding: 6rem;
  }
}

/* MEDIA QUERIES */
@media screen and (min-width: 420px) {
  .col-3-xs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .col-3-xs > div {
    width: 33.3333%;
  }
}
@media screen and (min-width: 576px) {
  .col-3-sm {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .col-3-sm > div {
    width: 33.3333%;
  }
  .col-2-sm {
    display: flex;
    flex-direction: row;
  }
  .col-2-sm > div {
    width: 50%;
  }
  /* .col-2-sm > div:first-of-type {
    padding-right: 1rem;
  } */
  /* .col-2-sm > div:nth-of-type(2) {
    padding-left: 1rem;
  } */
  .thirty-70.col-2-sm > div:first-of-type {
    padding-right: 1rem;
    width: 30%;
  }
  .thirty-70.col-2-sm > div:nth-of-type(2) {
    width: 70%;
    display: flex;
    justify-content: center;
    padding-left: 1rem;
  }
  .forty-60.col-2-sm > div:first-of-type {
    width: 40%;
    padding-right: 1rem;
  }
  .forty-60.col-2-sm > div:nth-of-type(2) {
    padding-left: 1rem;
    width: 60%;
    display: flex;
    justify-content: center;
  }
  .sixty-40.col-2-sm > div:first-of-type {
    width: 60%;
    padding-right: 1rem;
  }
  .sixty-40.col-2-sm > div:nth-of-type(2) {
    padding-left: 1rem;
    width: 40%;
  }
  .seventy-30.col-2-sm > div:first-of-type {
    padding-right: 1rem;
    width: 70%;
  }
  .seventy-30.col-2-sm > div:nth-of-type(2) {
    width: 30%;
    padding-left: 1rem;
  }

  .col-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  .test_now {
    border-bottom: none;
    border-right: 1px solid var(--border);
  }

  /* .vaccinated{flex-direction:row-reverse} */
}
@media screen and (min-width: 768px) {
  .col-3-md {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .col-3-md > div {
    width: 33.3333%;
  }
  .col-2,
  .col-2-md {
    display: flex;
    justify-content: space-between;
  }
  .col-2 > div,
  .col-2-md > div {
    width: 50%;
  }
  .col-2-md-reverse {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .col-2-md > div:first-of-type {
    padding-right: 1rem;
  }
  .col-2-md > div:nth-of-type(2) {
    padding-left: 1rem;
  }
  .forty-60.col-2-md > div:first-of-type {
    width: 40%;
    padding-right: 1rem;
  }
  .forty-60.col-2-md > div:nth-of-type(2) {
    width: 60%;
    padding-left: 1rem;
  }
  .sixty-40.col-2-md > div:first-of-type {
    width: 60%;
    padding-right: 1rem;
  }
  .sixty-40.col-2-md-reverse > div:nth-of-type(2) {
    width: 40%;
    padding-right: 1rem;
  }
  .sixty-40.col-2-md-reverse > div:first-of-type {
    width: 60%;
    padding-left: 1rem;
  }
  .sixty-40.col-2-md > div:nth-of-type(2) {
    width: 40%;
    padding-left: 1rem;
  }
  .seventy-30.col-2-md > div:first-of-type {
    width: 70%;
    padding-right: 1rem;
  }
  .seventy-30.col-2-md > div:nth-of-type(2) {
    width: 30%;
    padding-left: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .hide-under-sm{display: none;}
}
@media screen and (max-width: 768px) {
  .hide-under-md{display: none;}
}
@media screen and (min-width: 768px) {
  .hide-over-md{display: none;}
}

.highlight
{
    color: #ed1c24;
}