
/* MODAL */
.modal-overlay {
    z-index: 2;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-window {
    position: absolute;
    background: white;
    max-width: 50rem;
  }
  .modal-body{
    padding: 3rem;
  }
  .modal-header {
    padding:1rem 2rem;
    min-height:44px;
    margin-bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background:var(--highlighted);
  }
  .modal-close {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }
  

  @media screen and (min-width: 576px) {
    .modal-window {
      border-radius: var(--border_radius);
    }
    .modal-header{
      border-radius: var(--border_radius) var(--border_radius) 0 0;
    }
  }
