@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200;300;400;500;600;700;800;900&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
.newstitle {
  font-family: "Libre Franklin", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: right;
  font-weight: 700;
  font-weight: bold;
  opacity: .85;
}

html {
  color: var(--text_color);
}
b,
strong {
  font-weight: bold;
}

a {
  color: royalblue;
  transition: (--transition);
}
a:visited {
  color: purple;
}
a:hover,
a:focus,
a:active {
  color: var(--activelink);
}

p,
ul,
ol,
li,
button,
a,
.fas,
div {
  font-size: 16px;
  line-height: 150%;
}
nav div,
.attention-tested-positive span{
  font-size: 16px;
  line-height: 1.3;
}
h1 {
  font-size: 32px;
  line-height: 140%;
  margin-bottom: 1.5rem;
}
h2 {
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

h3 {
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 1.5rem;
  font-weight: 400;
}
ul,ol {
  margin: 0 0 0 1em;
}
p, li, ul {
  margin-bottom: 0.75rem;
}
/* p:last-child,li:last-child{margin-bottom:0;} */
a {
  cursor: pointer;
}

small {
  font-size: 75%;
}

@media screen and (min-width: 768px) {
  p,
  ul,
  ol,
  li,
  button,
  a,
  .fas,
  div {
    font-size: 18px;
  }
  h3 {
    font-size: 21px;
  }
}
@media screen and (min-width: 992px) {
  p,
  ul,
  ol,
  li,
  button,
  a,
  .fas,
  div {
    font-size: 19px;
  }
  h3 {
    font-size: 22px;
  }
  h2 {
    font-size: 25px;
  }
  .container{
    max-width: 1200px;
  }
  .flanked .section-text {
    max-width: 58rem;
  }
}

@media screen and (max-width: 600px) {
}


