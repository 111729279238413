.icon-card-container {
  display: flex;
  padding-right: 5px;
  overflow: hidden;
  align-items: stretch;
  align-content: stretch;
  justify-content: space-between;
  flex-direction: column;
}
.icon-card {
  background: white;
  border: 1px solid var(--border);
  border-radius: 10px;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom:1rem;
  flex-direction:column;
  justify-content:space-between;
}
.icon-card:last-of-type {}
.icon-card h3 {
  font-weight: bold;
  margin-bottom: 0;
  line-height:120%;
}
.icon-card p {
  margin-top: 0;
}
.icon-card-header{
  display:flex;
  margin-bottom:1rem;
}
.icon-card-header .step{
  font-size:1.4rem;
  font-weight:bold;
  opacity:.6;
}
.icon-card-header-left{
  display:flex;
}
.icon-circle-wrap {
  background: var(--dark_accent);
  border-radius: 500px;
  padding: 1.25rem;
  height: 50px;
  width: 50px;
  margin-right: 1rem;
  /* margin: 0 auto; */
  display:flex;
  align-items:center;
  justify-content:center;
  color:white;
}
.icon-circle img {
  height: 100%;
  display: flex;
}



@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
  .icon-card-container {flex-direction:row;}
  .icon-card{
    margin-right:1rem;
  }
  .icon-card:last-of-type { margin-right: 0; }
}
@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 1024px) {
}
