@import url("base/reset.css");
/* @import url("base/old-base-styles.css"); */
/* @import url("base/old-template-theme.css"); */

:root {
  --main_accent: #f36f2b; /* orange: #f36f2b; green: #39b54a , blue: #25aae1 , purple: #9f1f63 , red: #ed1c24 */
  --loud_accent: #ee1c25;
  --text_color: #162A40;
  --dark_accent: #255292;
  --nav_color: #5d6e81;
  --activelink: #00518d;
  --highlighted: #F4F7FB;
  --border: #ddd;
  --border_radius: 6px;
  --box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  font-family: "Libre Franklin", sans-serif;

  --main_accent_act: #107e77;
  --main_accent_act-gradient: linear-gradient(to right, #107e77, #076660);
  --dark_accent-act: #1e3838;
  --transition: all 0.5s ease;
}
html{
  scroll-behavior: smooth;
}
@import url("base/typ.css");
@import url("base/forms.css");
@import url("base/layout.css");

@import url("partials/language-switcher.css");
@import url("partials/nav.css");
@import url("partials/footer.css");
@import url("partials/icon-cards.css");
@import url("partials/modal.css");

@import url("pages/home.css");
@import url("pages/confirmation.css");

/* @import url("syctn-branding.css");
@import url("act-branding.css");
@import url("default-branding.css"); */


[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .6s;
}
[data-aos="zoom-y-out"] {
  opacity: 0;
  transition-property: transform, opacity;
}

.dark_accent{color:var(--dark_accent);}