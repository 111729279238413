footer {
  background-color: var(--dark_accent);
  color:white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
footer p, footer a, footer div{
  font-size:12px;
}
footer .language-switcher {justify-content:center;}
footer .language-switcher i {
  color: white;
}
footer .container {flex-direction:column;}
footer .language-switcher select {
  color: white;
  background: transparent;
}
footer .language-switcher option {
  color: black;
}
.footer-logo,.contact-info{flex:1}
.contact-info{padding-left:2rem;}
.footer-logo {
  max-width: 50%;
  text-align:right;
  padding-right: 2rem;
  border-right: 1px solid white;
  display: flex;
  align-items: center;
}
.footer-logo img {
  max-width: 120px;
}
.footer_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  margin-bottom:2rem;
}
.footer_right {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content:center;
  text-align:center;
}

@media screen and (min-width: 576px) {
  .footer_right {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  footer {
    flex-direction: row;
    justify-content: space-between;
  }
  footer .container {flex-direction:row;}
  footer p, footer a, footer div{
    font-size:14px;
  }
  footer p {margin-bottom:.25rem;}
  .footer_left {margin-bottom:0;}
  .footer-logo, .contact-info{flex:initial;}
  .footer_right {
    text-align:right;
    width: 50%;
    max-width: 540px;
  }
  footer .language-switcher{
    justify-content: flex-end;
  }
}
